import './index.scss';

// import * as test from "./modules/form.js"
// import { test } from './modules/form.js';
// import * as alias from './modules/comparison-slider/';
// import * as slideshow from './modules/slideshow/';
// import * as lax from './modules/lax/lax.js';
// import * as form from './modules/contact-form/index.js';
// import * as ga from './modules/ga/ga.js';

function toggleCookieReminder() {
	// check if cookie is existing
	var cookieArray = document.cookie.split(";");
	for(var i = 0; i < cookieArray.length; i++) {
		if(cookieArray[i].split('=')[0] == "cookies" || cookieArray[i].split('=')[0] == " cookies") {
			document.getElementById('cookie-reminder').style.display = "none";
		}
		else {
			if(i == cookieArray.length) {
				document.getElementById('cookie-reminder').style.display = "block";
			}
		}
		console.log("'" + cookieArray[i].split('=')[0] + "'");
	}

	// if cookie is existing set display none

	// if cookie is not existing set display block
}
toggleCookieReminder();

function cookieToggle() {
	var getDenie = document.getElementById('denie-cookies');
	var getAccept = document.getElementById('accept-cookies');

	getDenie.addEventListener("click", function() {
		// set cookie with value denie
		document.cookie = "cookies=denied";
		window.location.reload();

	});
	getAccept.addEventListener("click", function() {
		// set cookie with value accept
		document.cookie = "cookies=accepted";
		window.location.reload();
	});
}
cookieToggle();

document.addEventListener('scroll', function() {
	let offsetTop = document.querySelector('body').getBoundingClientRect().top;
	let scrollIcon = document.querySelector('.icon-scroll');
	if(scrollIcon) {
		if (offsetTop < -100 && offsetTop != 0) {
			scrollIcon.classList.add('hide');
		} else {
			scrollIcon.classList.remove('hide');
		}
	}
});

document.querySelectorAll('.image').forEach(function(elem) {
	var x = undefined,
		width = undefined;

	elem.onmouseenter = function() {
		var size = elem.getBoundingClientRect();

		x = size.x;
		width = size.width;
	};

	elem.onmousemove = function(e) {
		var horizontal = (e.clientX - x) / width * 100;

		elem.style.setProperty('--x', horizontal + '%');
	};
});

window.addEventListener('load', function() {
	console.log('fully loaded');
});

function toggleCheckboxes() {
    var getAllCheckboxes = document.querySelectorAll('li.formular-checker');
    for(var i = 0; i < getAllCheckboxes.length; i++) {
        const singleCheckbox = getAllCheckboxes[i];
        singleCheckbox.addEventListener("click", function() {
            if(this.classList.contains('checked')) {
                this.classList.remove('checked');
            }
            else{
                this.classList.add('checked');
            }
        });
    }
}
toggleCheckboxes();


// function to make quote author smaller
function addQuoteAuthorTag() {
	var getAllQuotes = document.querySelectorAll('blockquote');
	if(getAllQuotes.length == 0) {
		// no quotes
	}
	else {
		for (let i = 0; i < getAllQuotes.length; i++) {
			const singleQuote = getAllQuotes[i];

			// detect author
			var getQuoteValue = singleQuote.innerHTML;

			// split text
			var getQuoteWords = getQuoteValue.split(" ");

			var authorString = "";
			var newText = "";
			var keepFilling = false;
			for(var ii = 0; ii < getQuoteWords.length; ii++) {
				if(keepFilling == true) {
					if(getQuoteWords[ii].indexOf(']') > -1) {
						newText += getQuoteWords[ii] + "</span>";
						keepFilling = false;
						//console.log("add span end " + ii);
					}
					else {
						newText += getQuoteWords[ii] + " ";
						//console.log("add words(keepfilling=true) " + ii);
					}
				}
				else {
					if(getQuoteWords[ii].indexOf('[') > -1) {
						newText += "<span class='quote-author'>" + getQuoteWords[ii];
						//console.log("start quote " + ii);
						keepFilling = true;
					}
					else {
						newText += getQuoteWords[ii] + " ";
						//console.log("add words(keepfilling=false) " + ii);
					}
				}
				// console.log(newText);
				// console.log(authorString);
				

			}
			getAllQuotes[i].innerHTML = newText;
		}
	}
}
addQuoteAuthorTag();

function initFormularBeratung() {
    
	// Adds a listener for the "submit" event.
	var form = document.getElementById('form_beratung');
	//console.log(form)
	if(form) {
		var submit = form.querySelector('button[type="submit"]');

		submit.addEventListener('click', function(e) {
			e.preventDefault();
	
			// form fields
			var lastname = form.querySelector('.inp-lastname');
			var firstname = form.querySelector('.inp-firstname');
			var email = form.querySelector('.inp-mail');
			var anfrage = form.querySelector('.inp-textarea');
			var confirm = form.querySelector('#beratung-confirm');
			var sendTo = document.getElementById('form_beratung').getAttribute('data-mail');
			var formType = "beratung";
			
			// ajax call function 
			var xhttp = new XMLHttpRequest();
	
			var urlBase = window.location.host;
			var subDomain = "";
			xhttp.open("POST", subDomain, true);
			xhttp.setRequestHeader('X-Requested-With','XMLHttpRequest');
			xhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
			// xhttp.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
			xhttp.onreadystatechange = function() {
	
				if (this.readyState == 4 && this.status == 200) {
	
						var resp = JSON.parse(this.responseText);

	
					if(Object.values(resp)[0] != null) {
						var alert_object = Object.values(resp)[0];
						// loop through to highlight areas
						var invalid = false;
						for(var i = 0; i < Object.keys(alert_object).length; i++) {
							var current = Object.keys(alert_object)[i];
	
							if(current == "firstname") {
								form.querySelector('.inp-firstname').classList.add('missing');
								invalid = true;
							} 
							if(current == "lastname") {
								form.querySelector('.inp-lastname').classList.add('missing');
								invalid = true;
							} 
							if(current == "email") {
								form.querySelector('.inp-mail').classList.add('missing');
								invalid = true;
							} 
							if(current == "anfrage") {
								form.querySelector('.inp-textarea').classList.add('missing');
								invalid = true;
							}	
							if(current == "confirm") {
								confirm.parentNode.classList.add('missing');
								invalid = true;
							}
							if(invalid == true) {
								alert("Bitte füllen Sie die Pflichtfelder (*) aus.")
							}
						};
	
					} else if(resp.success) {
	
						// close form and show success msg
	
						alert(resp.success)
						// clear form
						// var inputs = form.querySelectorAll('input');
						// for(var i = 0; i < inputs.length; i++) {
						// 	inputs[i].value = "";
						// }
						// form.querySelector('Textarea').value = "";
						window.location.reload();
	
						// form.querySelector('.before_send').classList.add('closed');
						// form.parentNode.querySelector('.send_successfull').classList.add('open');
						// form.querySelector('.msg_success').innerHTML = resp.success;
					}
					else {
						// cant send mail
						alert('Aus technischen Gründen kann derzeit keine Anfrage über dieses Formular versendet werden. Wir bitten Sie, Ihre Anfragen direkt zu senden an: hello@dart.work');
					}
				}
			};
	
			var string = 
				'submit=' + 'true&' +
				'sendto=' + sendTo + "&" +
				'lastname=' + lastname.value + "&" +
				'firstname=' + firstname.value + "&" +
				'email=' + email.value + "&" +
				'anfrage=' + anfrage.value + "&" + 
				'confirm=' + confirm.checked + "&" +
				'form=' + formType;
				if(confirm.checked == true) {
					string += "&confirm=true";
				}
				else {
					string += "&confirm=";
				}

			// pass data to the controller
			xhttp.send(string);
		});
	}
	//console.log(submit);
}   
initFormularBeratung();



function initFormularDownload() {
	var form = document.getElementById('form_download');
	if(form) {
		var submit = form.querySelector('button[type="submit"]');

		submit.addEventListener("click", function(e) {
			e.preventDefault();

			// create string for download values
			var downloadArray = [];
			var checkboxes = document.querySelectorAll('.formular-checkboxes')[0];
			if(checkboxes) {
				var checkboxesLength = checkboxes.querySelectorAll('ul');
				for(var i = 0; i < checkboxesLength.length; i++) {
					// check status
					var getAllLis = checkboxesLength[i].querySelectorAll('li');
					for(var ii = 0; ii < getAllLis.length; ii++) {
						var singleCheckbox = getAllLis[ii];
						if(singleCheckbox.classList.contains('checked')) {
							// add value to download-list
							// downloadArray.push(singleCheckbox.id);
							downloadArray.push(singleCheckbox.getAttribute('data-url'));
						}

					}
				}
			}

			// form fields
			var firstname = form.querySelector('.inp-firstname');
			var lastname = form.querySelector('.inp-lastname');
			var email = form.querySelector('.inp-mail');
			var sendTo = form.getAttribute('data-mail');
			var confirm = form.querySelector('#download-confirm');
			var formType = "download";
			var page = window.location.href;

			// ajax call function 
			var xhttp = new XMLHttpRequest();

			var subDomain = "";
			xhttp.open("POST", subDomain, true);
			xhttp.setRequestHeader('X-Requested-With','XMLHttpRequest');
			xhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
			// xhttp.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
			xhttp.onreadystatechange = function() {

				if (this.readyState == 4 && this.status == 200) {

						// console.log(this.response);

						var resp = JSON.parse(this.responseText);

					if(Object.values(resp)[0] != null) {
						var alert_object = Object.values(resp)[0];
						// loop through to highlight areas
						var invalid = false;
						for(var i = 0; i < Object.keys(alert_object).length; i++) {
							var current = Object.keys(alert_object)[i];

							if(current == "firstname") {
								form.querySelector('.inp-firstname').classList.add('missing');
								invalid = true;
							} 
							if(current == "lastname") {
								form.querySelector('.inp-lastname').classList.add('missing');
								invalid = true;
							} 
							if(current == "email") {
								form.querySelector('.inp-mail').classList.add('missing');
								invalid = true;
							}
							if(current == "confirm") {
								confirm.parentNode.classList.add('missing');
								invalid = true;
							}
							if(current == "download") {
								alert("Bitte wählen Sie mindestens ein Dokument aus.")
							}
							window.location.reload();
						};
						if(invalid == true) {
							alert("Bitte füllen Sie die Pflichtfelder (*) aus.")
						}

					} else if(resp.success) {
						console.log("SUCCESS LUL");

						// close form and show success msg

						alert(resp.success)
						// clear form
						var inputs = form.querySelectorAll('input');
						for(var i = 0; i < inputs.length; i++) {
							inputs[i].value = "";
						}
					}
					else {
						// cant send mail
						alert('Aus technischen Gründen kann derzeit keine Anfrage über dieses Formular versendet werden. Wir bitten Sie, Ihre Anfragen direkt zu senden an: hello@dart.work');
					}
				}
			};

			var string = 
			'submit=' + 'true&' +
			'sendto=' + sendTo + "&" +
			'lastname=' + lastname.value + "&" +
			'firstname=' + firstname.value + "&" +
			'email=' + email.value + "&" +
			'form=' + formType + "&" +
			'page=' + page + "&" +
			'download=';

			if(downloadArray.length != 0) {
				string += "[";
				for(var ii = 0; ii < downloadArray.length; ii++) {
					if(ii == downloadArray.length) {
						string += downloadArray[ii];
					}
					string += downloadArray[ii] + ",";
				}
				string += "]";
			}
			if(confirm.checked == true) {
				string += "&confirm=true";
			}
			else {
				string += "&confirm=";
			}

			console.log(string);

			// pass data to the controller
			xhttp.send(string);
		});
	}
	
}
initFormularDownload();


// set keyvisual styling
function setKeyvisualStyling() {
	var getImage = document.querySelectorAll('.keyvisual-homepage')[0];
	var getImageRatio = document.querySelectorAll('.keyvisual-homepage')[0].getAttribute('data-dimension');

	if(getImageRatio) {
		var imageOriginalWidth = getImageRatio.split(" ")[0];
		var imageOriginalHeight = getImageRatio.split(" ")[2];
		// calculate ratio down with height of container
		var imageHeight = document.querySelectorAll('.keyvisual-homepage')[0].scrollHeight;
		var calculatedHeightFac = imageHeight / imageOriginalHeight;
		var calculatedWidth = imageOriginalWidth * calculatedHeightFac;
	
		// Check for mobile
		if(imageHeight < 300) {
			if(getImage.classList.contains('contain')) {
				getImage.classList.remove('contain');
				getImage.classList.add('cover');
			}
			else {
				if(getImage.classList.contains('cover') == false) {
					getImage.classList.add('cover');
				}
			}
		}
		// Check if image has to be set to background-size: contain or cover
		else if(window.innerWidth < calculatedWidth) {
			// set background image to cover
			if(getImage.classList.contains('contain')){
				getImage.classList.remove('contain');
			}
			getImage.classList.add('cover');
	
		}
		else {
			// set background image to contain
			if(getImage.classList.contains('cover')){
				getImage.classList.remove('cover')
			}
			getImage.classList.add('contain');
		}
	}
	
}
setKeyvisualStyling();

function checkLargeDesktopForm() {
	var getForm = document.getElementById('form_beratung');
	if(window.innerWidth > 1919) {
		// desktop large
		if(getForm) {
			// get window scrollheight
			var windowOffset = window.pageYOffset;

			if(windowOffset < 380) {
				// set position depending on scrollposition
				getForm.style = "top: " + (windowOffset * -1 + 600) + "px;";
			}
			else {
				// go into fixed value
				getForm.style = "top: 224px;";
			}
		}
	}
	else {
		if(getForm) {
			getForm.style="top: 0;";
		}
	}
	
}
checkLargeDesktopForm();

function toggleMobileSidebar() {
	var toggle = false;
	var getTrigger = document.getElementById('mobile-bar-activator-hitbox');
	var getSidebar = document.getElementById('mobile-sidebar');
	var getSidebarOverlay = document.getElementById('mobile-sidebar-overlay');

	getTrigger.addEventListener("click", function() {
		if(toggle == false) {
			getSidebar.classList.add('active');
			getSidebarOverlay.classList.add('active');
			toggle = true;
		}
		else {
			getSidebar.classList.remove('active');
			getSidebarOverlay.classList.remove('active');
			toggle = false;
		}
	});
}
toggleMobileSidebar();

window.onload = function() {
	document.querySelector('body').classList.remove("preload");
};

// check background image styling on rezise
window.onresize = function() {
	setKeyvisualStyling();
	checkLargeDesktopForm();
}

window.onscroll = function() {
	checkLargeDesktopForm();
}

